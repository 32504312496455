import { gsap, ScrollTrigger } from "gsap/all"

gsap.registerPlugin(ScrollTrigger)

export default () => ({
  init() {
    const barEl = this.$refs.bar
    if (!barEl) return

    gsap.to(barEl, {
      scrollTrigger: {
        trigger: barEl,
        scrub: true,
        start: "clamp(top bottom)",
        end: "clamp(250% bottom)",
      },
      y: 0,
    })
  },
})
