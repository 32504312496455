import { register as registerSwiper } from "swiper/element/bundle"

registerSwiper()

export default () => ({
  init() {
    const swiperEl = this.$el

    const swiperParams = {
      pagination: {
        el: this.$refs.pagination,
        bulletClass:
          "w-1.5 aspect-square rounded-full border focus:outline-none",
        bulletActiveClass: "bg-current",
        renderBullet: (_, className) =>
          `<button class="${className}"></button>`,
        clickable: true,
      },
      loop: true,
      effect: "fade",
      crossFade: true,
    }
    Object.assign(swiperEl, swiperParams)

    swiperEl.initialize()
  },
})
