import gsap from "gsap"

export default (shouldAnimate = true) => ({
  init() {
    if (!shouldAnimate) return

    gsap.to(this.$el.children, {
      duration: 1,
      opacity: "random(0.5, 1)",
      repeat: -1,
      repeatRefresh: true,
      stagger: {
        grid: [3, 3],
        amount: 1,
        from: "random",
      },
    })
  },
})
