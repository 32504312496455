import "../css/app.css"
import Alpine from "alpinejs"
import focus from "@alpinejs/focus"
import * as htmx from "htmx.org"
import Lenis from "lenis"

import hero from "./components/hero"
import heroCarousel from "./components/heroCarousel"
import heroVideo from "./components/heroVideo"
import boxes from "./components/boxes"
import carousel from "./components/carousel"
import modalVideo from "./components/modalVideo"
import textUnderline from "./components/textUnderline"
import parallaxImage from "./components/parallaxImage"

import modal from "./stores/modal"
import global from "./stores/global"

// Lenis
const lenis = new Lenis()

function raf(time: number) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)

// Alpine
window.Alpine = Alpine

Alpine.data("hero", hero)
Alpine.data("heroCarousel", heroCarousel)
Alpine.data("heroVideo", heroVideo)
Alpine.data("boxes", boxes)
Alpine.data("carousel", carousel)
Alpine.data("modalVideo", modalVideo)
Alpine.data("textUnderline", textUnderline)
Alpine.data("parallaxImage", parallaxImage)

Alpine.store("modal", modal)
Alpine.store("global", global)

Alpine.plugin(focus)

Alpine.start()

// HTMX
window.htmx = htmx
