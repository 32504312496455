import { Stores } from "alpinejs"

const modal: Stores & { isOpen: boolean, contentEl: Element | null } = {
  isOpen: false,
  contentEl: null,
  open(contentEl: Element) {
    this.contentEl = contentEl
    this.isOpen = true
  },
  close() {
    this.isOpen = false
    this.contentEl = null
  },
}

export default modal
