import Plyr from "plyr"

export default (url: string) => ({
  player: null,
  parentEl: null,
  resizeObserver: null,
  init() {
    this.createPlayer()
    this.maintainAspectRatio()
  },
  destroy() {
    this.resizeObserver.unobserve(this.parentEl)
  },
  createPlayer() {
    const videoHolderEl = this.$refs.videoHolder
    const videoEl = this.getVideoElement()
    videoHolderEl.append(videoEl)

    this.player = new Plyr(
      videoEl.tagName === "VIDEO" ? videoEl : videoHolderEl,
      {
        autoplay: true,
        seekTime: 15,
        resetOnEnd: true,
        controls: [
          "play",
          "rewind",
          "fast-forward",
          "mute",
          "captions",
          "fullscreen",
          "settings",
        ],
      },
    )
  },
  maintainAspectRatio() {
    const handleParentResize = () => {
      const isParentTaller =
        this.parentEl.clientWidth / this.parentEl.clientHeight < 16 / 9

      Object.assign(this.$root.style, {
        width: isParentTaller ? "100%" : "auto",
        height: isParentTaller ? "auto" : "100%",
      })
    }

    this.parentEl = this.$root.parentElement
    this.resizeObserver = new ResizeObserver(handleParentResize)
    this.resizeObserver.observe(this.parentEl)
  },
  getVideoElement() {
    const match = url.match(/vimeo.*\/(\d+)/i)
    if (match) {
      const vimeoId = match[1]
      const iframeEl = document.createElement("iframe")
      Object.assign(iframeEl, {
        src: `https://player.vimeo.com/video/${vimeoId}`,
        allowFullscreen: true,
      })

      return iframeEl
    }

    const videoEl = document.createElement("video")
    Object.assign(videoEl, { src: url })

    return videoEl
  },
})
