import { gsap, ScrollTrigger } from "gsap/all"

gsap.registerPlugin(ScrollTrigger)

export default () => ({
  init() {
    const imageEl = this.$refs.image
    if (!imageEl) return

    const transformAmount = -1 * (imageEl.offsetHeight - this.$el.offsetHeight)

    gsap.to(imageEl, {
      scrollTrigger: {
        trigger: this.$el,
        scrub: 0.5,
        start: "top bottom",
        end: "bottom top",
      },
      ease: "none",
      y: transformAmount,
    })
  },
})
