export default (url: string) => ({
  async init() {
    const videoHolderEl = this.$refs.videoHolder
    if (!videoHolderEl) return

    const match = url.match(/vimeo.*\/(\d+)/i)
    if (match) {
      const vimeoId = match[1]
      const oEmbedResponse = await fetch(
        `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${vimeoId}`,
      )
      const oEmbedResponseJson = (await oEmbedResponse.json()) as {
        width: number
        height: number
      }
      const { width, height } = oEmbedResponseJson

      const iframeEl = document.createElement("iframe")
      iframeEl.classList.add("w-full", "h-full")
      iframeEl.src = `https://player.vimeo.com/video/${vimeoId}?background=1&autoplay=1&autopause=0&loop=1&byline=0&title=0`
      iframeEl.allowFullscreen = false

      videoHolderEl.style.aspectRatio = `${width}/${height}`
      videoHolderEl.append(iframeEl)
      return;
    }

    const videoEl = document.createElement("video")
    videoEl.classList.add("w-full", "h-full")
    videoEl.src = url
    videoEl.muted = true
    videoEl.autoplay = true
    videoEl.loop = true

    videoHolderEl.append(videoEl)
  },
})
