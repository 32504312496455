import { gsap, ScrollToPlugin } from "gsap/all"

gsap.registerPlugin(ScrollToPlugin)

export default {
  async scrollPage(to: string | Element) {
    let toEl = null

    if (typeof to === 'string') {
      toEl = document.querySelector(to)
    } else {
      toEl = to
    }

    if (!toEl) return

    const rootStyles = getComputedStyle(document.documentElement)
    const siteHeaderHeight = parseInt(rootStyles.getPropertyValue('--site-header-height').replace('px', ''))
    const willScrollUp = toEl.getBoundingClientRect().top < 0

    await gsap.to(
      window, 
      { 
        duration: 1, 
        scrollTo: { 
          y: toEl, 
          offsetY: willScrollUp ? siteHeaderHeight : 0, 
          autoKill: true 
        } 
    })
  }
}
